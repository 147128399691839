<template>
    <div>
        <v-label>
            <span class="text--primary d-block pb-2 font-weight-bold">{{ title }}</span>
        </v-label>
        <v-label>Ultima actualización: {{ updated || "N/A" }}</v-label>
        <v-row>
            <v-col cols="12">
                <v-file-input @change="update" show-size solo dense flat outlined hide-details :label="placeholder" truncate-length="50" :accept="accept" :rules="rules" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: ['value', 'title', 'placeholder', 'updated', 'rules', 'accept'],
    methods: {
        update(file) {
            this.$emit('input', file);
        }
    }
}
</script>