<template>
    <DashCard title="Análisis de Datos" :actions="true" actions-align="right">
            <v-row>
                <v-col cols="12">
                    <v-label>
                        <span class="text--primary">
                        Este documento contiene un resumen (dashboard) de toda la actividad de las estaciones <b>Hotel Mina, Chacay Cuncumén y Caimanes</b> 
                        considerando un rango de tiempo personalizable.
                        </span>
                    </v-label>
                </v-col>
            </v-row>
            <v-divider class="my-2"/>
            <v-label>Seleccione el intervalo sobre la cual se generará la información del documento.</v-label>
            <v-row v-if="!demo">
                <v-col cols="12" sm="6">
                    <v-label>Inicio</v-label>
                    <DatePickerText dense flat v-model="inicio" />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-label>Termino</v-label>
                    <DatePickerText dense flat v-model="termino" />
                </v-col>
            </v-row>
            <v-alert type="info" v-else>
                Esta función está en desarrollo.
            </v-alert>
        <template v-slot:actions>
            <template v-if="!demo">
                <v-btn depressed dark large color="primary" class="subtitle-2" @click="get_download" :loading="loading">
                    <v-icon left>get_app</v-icon> Descargar (XLSX)
                </v-btn>
            </template>
        </template>
        <DownloadDialog ref="down_dialog" />
    </DashCard>
</template>

<script>
import DatePickerText from '@/shared/components/DatePickerText'
import DownloadDialog from '@/shared/components/DownloadDialog'
import DashCard from '@/shared/components/DashCard'
import Message from '@/shared/components/Message'
import { axiosHelper, messages } from '@/helpers'

import download from 'downloadjs'
import moment from 'moment'

export default {
    props: ['demo'],
    components: {
        DashCard,
        Message,
        DatePickerText,
        DownloadDialog
    },
    data: () => ({
        inicio: moment().format("YYYY-MM-DD"),
        termino: moment().format("YYYY-MM-DD"),
        loading: false
    }),
    methods: {
        get_download() {
            this.$refs.down_dialog.show('repdiarios/', { tipo: "analisis", inicio: this.inicio, termino: this.termino });
        }
    }
}
</script>