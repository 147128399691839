<template>
    <div>
        <v-row v-if="all_disabled">
            <v-col>
                <AccessMessage />
            </v-col>
        </v-row>
        <div v-else>
            <v-row v-if="upload_enabled">
                <v-col>
                    <v-btn depressed dark large color="secondary" class="subtitle-2 mx-1" @click="$refs.dialog_req.show()">
                        <v-icon left>publish</v-icon> Subir material diario
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6" v-if="this.$parent.$parent.get_permission('export_1')">
                    <ReporteDiario1 />
                </v-col>
                <v-col cols="12" md="6" v-if="this.$parent.$parent.get_permission('export_2')">
                    <ReporteDiario2 />
                </v-col>
                <v-col cols="12" md="6" v-if="this.$parent.$parent.get_permission('export_3')">
                    <ReporteDiario3 />
                </v-col>
            </v-row>
            <DialogMaterialReq ref="dialog_req" />
        </div>
    </div>
</template>

<script>
import DashCard from '@/shared/components/DashCard'
import { axiosHelper } from '@/helpers'

import DialogMaterialReq from '@/dash/dialogs/DialogMaterialReq'
import ReporteDiario1 from '@/dash/form_boxes/box_daily_1'
import ReporteDiario2 from '@/dash/form_boxes/box_daily_2'
import ReporteDiario3 from '@/dash/form_boxes/box_daily_3'
import AccessMessage from '@/dash/components/AccessMessage'

export default {
    components: {
        DashCard,
        ReporteDiario1, 
        ReporteDiario2, 
        ReporteDiario3,
        DialogMaterialReq,
        AccessMessage
    },
    data: () => ({

    }),
    computed: {
        upload_enabled() {
            return this.$parent.$parent.get_permission('upload') && this.$parent.$parent.get_permission('export_3'); 
        },
        all_disabled() {
            return !this.$parent.$parent.get_permission('export_1') && 
            !this.$parent.$parent.get_permission('export_2') && 
            !this.$parent.$parent.get_permission('export_3');
        }
    },
    created() {
        this.$parent.$parent.disable_app_indicator();
    },
    mounted() {
        this.$emit('title', { text: "Reportes diarios", icon: "description" });
    }
}
</script>