<template>
    <DashCard title="Informe ejecutivo" :actions="true" actions-align="right">
        <v-label>
            <span class="text--primary">
                Este documento contiene un resumen diario que la concentración de Material Particulado (MP10) en gráficos de la estación 
                <b>Hotel Mina</b>, adicionando datos de Cuncumén y Chacay para su comparación.<br /><br />
                El documento contiene únicamente gráficos, detalle de Nefobasímetros y datos básicos, funciona a modo de plantilla para rellenar observaciones de lo que se presenten en los gráficos.
            </span>
        </v-label>
        <v-divider class="my-2"/>
        <v-label>Seleccione la fecha sobre la cual se generará la información del documento.</v-label>
        <v-row>
            <v-col cols="12" md="6" lg="6">
                <v-label>Fecha</v-label>
                <DatePickerText dense flat v-model="fecha" />
            </v-col>
            <v-col cols="12" md="6" lg="6">
                <v-label>Específicar estaciones</v-label>
                <v-select solo outlined flat dense :items="modes" v-model="modo" prepend-inner-icon="navigation" />
            </v-col>
        </v-row>
        <template v-slot:actions>
            <v-btn depressed dark large color="primary" class="subtitle-2" @click="get_download">
                <v-icon left>get_app</v-icon> Descargar (DOCX)
            </v-btn>
        </template>
        <DownloadDialog ref="down_dialog" />
    </DashCard>
</template>

<script>
import DatePickerText from '@/shared/components/DatePickerText'
import DownloadDialog from '@/shared/components/DownloadDialog'
import DashCard from '@/shared/components/DashCard'
import Message from '@/shared/components/Message'
import { axiosHelper, messages } from '@/helpers'

import download from 'downloadjs'
import moment from 'moment'

export default {
    components: {
        DashCard,
        DownloadDialog,
        Message,
        DatePickerText
    },
    data: () => ({
        fecha: moment().format("YYYY-MM-DD"),
        modo: 0,
        modes: [
            { "value": 0, "text": "Hotel Mina, Cuncumen y Chacay" },
            { "value": 1, "text": "Mauro y Caimanes" }
        ]
    }),
    methods: {
        get_download() {
            this.$refs.down_dialog.show('repdiarios/', { tipo: "ejecutivo", modo: this.modo, fecha: this.fecha });
        }
    }
}
</script>