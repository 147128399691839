<template>
    <v-dialog v-model="dialog.visible" max-width="800" persistent transition="fade-transition">
        <WindowCard title="Subir material requerido" actions-align="right">
            <v-label>Haga clic en cada cuadro para asignar un archivo.</v-label>
            <v-form id="form" ref="creator_form">
                <v-row>
                    <v-col cols="12">
                        <Uploader 
                            v-model="creator.main.descargas" 
                            accept=".xlsx,.xls"
                            :updated="dialog.updates.descargas | moment('dddd, LL HH:mm:ss')"
                            title="Tabla horaria de descargas" 
                            placeholder="descargas.xlsx" />
                    </v-col>
                    <v-col cols="12">
                        <Uploader
                            v-model="creator.main.detenciones"
                            accept=".xlsx,.xls"
                            :updated="dialog.updates.detenciones | moment('dddd, LL HH:mm:ss')"
                            title="Tabla horaria de detenciones"
                            placeholder="detenciones.xlsx" />
                    </v-col>
                    <v-col cols="12" v-show="false">
                        <Uploader
                            v-model="creator.main.promedio_hora" 
                            accept=".xlsx,.xls"
                            :updated="dialog.updates.promedio_hora | moment('dddd, LL HH:mm:ss')"
                            title="Promedio Horario MP10, Dirección y Velocidad de Viento"
                            placeholder="promedio_hora.xlsx" />
                    </v-col>
                    <v-col cols="12" v-show="false">
                        <Uploader
                            v-model="creator.main.promedio_movil"
                            accept=".xlsx,.xls"
                            :updated="dialog.updates.promedio_movil | moment('dddd, LL HH:mm:ss')"
                            title="Promedio Movil MP10, Dirección y Velocidad de Viento"
                            placeholder="promedio_movil.xlsx" />
                    </v-col>
                    <v-col cols="12" v-show="dialog.uploading">
                        <v-progress-linear :value="dialog.progress" striped rounded height="32" color="secondary">
                            <template v-slot="{ value }">
                                <strong>{{ Math.ceil(value) }}%</strong>
                            </template>
                        </v-progress-linear>
                    </v-col>
                </v-row>
            </v-form>
            <template slot="actions">
                <v-btn text large color="primary" @click="close()" v-show="!dialog.uploading">
                    <span class="subtitle-2">Descartar</span>
                </v-btn>
                <v-btn large depressed color="primary" type="submit" form="creator_form" @click="upload" :loading="dialog.uploading">
                    <v-icon>publish</v-icon>
                    <span class="ml-2 subtitle-2">Subir archivo</span>
                </v-btn>
            </template>
        </WindowCard>
        <Message ref="error" />
        <Message ref="dialog" @onClose="$emit('success')" />
    </v-dialog>
</template>

<script>
import WindowCard from '@/shared/components/WindowCard'
import Message from '@/shared/components/Message'
import Uploader from '@/dash/components/Uploader'
import { axiosHelper, messages, valid_required } from '@/helpers'

export default {
    props: ['empleados'],
    components: {
        WindowCard,
        Message,
        Uploader
    },
    data: () => ({
        dialog: {
            visible: false,
            progress: 0,
            updates: {},
            uploading: false,
        },
        creator: {
            main: {
                descargas: null,
                detenciones: null,
                promedio_hora: null,
                promedio_movil: null
            },
            edit: false
        },
        creation_rules: {
            required: value => valid_required(value)
        }
    }),
    methods: {
        show() {
            if(this.$refs.creator_form)
                this.$refs.creator_form.reset();
                
            axiosHelper.get('dailymat/updated_dates')
                .then(response => this.dialog.updates = response.data);

            this.dialog.visible = true;
        },
        close() {
            this.dialog.visible = false;
            this.dialog.updates = {};
            this.creator.main = {};

            if(this.$refs.creator_form)
                this.$refs.creator_form.reset();
        },
        error(custom) {
            let text = custom || messages.texts["error_check_fields"];
            this.$refs.error.show(messages.titles["error_generico"], text, "error");
            this.dialog.uploading = false;
        },
        upload() 
        {
            if(!this.$refs.creator_form.validate()) 
            {
                this.error();
                return;
            }

            if(!Object.values(this.creator.main).some(x => !!x)) {
                this.error("Al menos un archivo debe estar presente para poder actualizar el material.");
                return;
            }

            this.dialog.uploading = true;
            let form_data = new FormData();

            if(!!this.creator.main.descargas)
                form_data.append('descargas', this.creator.main.descargas);

            if(!!this.creator.main.detenciones)
                form_data.append('detenciones', this.creator.main.detenciones);

            if(!!this.creator.main.promedio_hora)
                form_data.append('promedio_hora', this.creator.main.promedio_hora);

            if(!!this.creator.main.promedio_movil)
                form_data.append('promedio_movil', this.creator.main.promedio_movil);

            let options = { onUploadProgress: progressEvent => this.dialog.progress = (progressEvent.loaded / progressEvent.total) * 100 };

            axiosHelper.post('dailymat/', form_data, { 'content-type': 'multipart/form-data' }, options)
                .then(response => {
                    this.dialog.uploading = false;
                    this.$refs.dialog.show(messages.titles["success_generico"], "Los archivos han sido subidos/actualizados con éxito", "success");
                    this.close();
                })
                .catch(error => this.error(messages.texts["error_desconocido"]));
        }
    }
}
</script>