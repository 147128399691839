<template>
    <DashCard title="Reporte de Alertas" :actions="true" actions-align="right">
            <v-row>
                <v-col cols="12" md="6">
                    <v-label>
                        <span class="text--primary">
                        Este documento contiene un resumen del estado de alertas dadas por pronóstico, 
                        junto a las detenciones horarias, y un análisis gráfico del estado de las concentraciones de MP10, 
                        la dirección y velocidad del viento de un día específico, dentro de la estación <b>Hotel Mina</b>.
                        </span>
                    </v-label>
                </v-col>
                <v-col cols="12" md="6">
                    <v-alert outlined type="info" color="primary">
                            <span>
                                Este documento requiere que el siguiente material haya sido subido previamente:
                                <ul>
                                    <li>Tabla de Descargas</li>
                                    <li>Tabla de Detenciones Horaria</li>
                                </ul>
                            </span>
                    </v-alert>
                </v-col>
            </v-row>
            <v-divider class="my-2"/>
            <v-label>Seleccione la fecha sobre la cual se generará la información del documento.</v-label>
            <v-row v-if="!demo">
                <v-col cols="12" md="6">
                    <v-label>Fecha</v-label>
                    <DatePickerText dense flat v-model="fecha" />
                </v-col>
            </v-row>
            <v-alert type="info" v-else>
                Esta función está en desarrollo.
            </v-alert>
        <template v-slot:actions>
            <template v-if="!demo">
            <v-btn depressed dark large color="primary" class="subtitle-2" @click="get_download">
                <v-icon left>get_app</v-icon> Descargar (XLSX)
            </v-btn>
            </template>
        </template>
        <DownloadDialog ref="down_dialog" />
    </DashCard>
</template>

<script>
import DatePickerText from '@/shared/components/DatePickerText'
import DownloadDialog from '@/shared/components/DownloadDialog'
import DashCard from '@/shared/components/DashCard'
import Message from '@/shared/components/Message'
import { axiosHelper, messages } from '@/helpers'

import download from 'downloadjs'
import moment from 'moment'

export default {
    props: ['demo'],
    components: {
        DashCard,
        Message,
        DatePickerText,
        DownloadDialog
    },
    data: () => ({
        fecha: moment().format("YYYY-MM-DD")
    }),
    methods: {
        get_download() {
            this.$refs.down_dialog.show('repdiarios/', { tipo: "alertas", fecha: this.fecha });
        }
    }
}
</script>